import moment from 'moment'

import {TaskRepetition, TaskRepetitionDto} from '../types'

export const mapTaskRepetitionToTaskRepetitionDto = (
  repetition: TaskRepetition
): TaskRepetitionDto => ({
  ...repetition,
  ...('startDate' in repetition
    ? {
        startDate: repetition.startDate.toISOString(),
        ...(repetition.endDate && {
          endDate: repetition.endDate?.toISOString()
        })
      }
    : {})
})

export const mapTaskRepetitionDtoToTaskRepetition = (
  repetitionInfo: TaskRepetitionDto,
  timezone: string
): TaskRepetition | undefined => ({
  ...repetitionInfo,
  ...('startDate' in repetitionInfo
    ? {
        startDate: moment.utc(repetitionInfo.startDate).tz(timezone),
        ...(repetitionInfo.endDate && {
          endDate: moment.utc(repetitionInfo.endDate).tz(timezone)
        })
      }
    : {})
})
