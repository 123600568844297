import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {EquipmentData} from '../types'

import {useApi} from './useApi'

const ITEMS_PER_RESPONSE = 100

export const useMainEquipmentNumberSearchQuery = (
  searchTerm: string | undefined,
  plantId: string,
  options?: UseQueryOptions<EquipmentData[], AxiosError>
) => {
  const {publicApi} = useApi()
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<EquipmentData[], AxiosError>(
    ['mainEquipmentNumberSearch', searchTerm, language, plantId],
    async () => {
      const path = `/plantconfig/${plantId}/mainequipmentnumbers`
      const params = {
        params: {
          searchPattern: searchTerm,
          itemsPerResponse: ITEMS_PER_RESPONSE,
          language
        }
      }

      const response = await publicApi.get<EquipmentData[]>(path, params)
      return response.data
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
