import {dataTestId, formatTons} from '@hconnect/uikit/src/common'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Box, Typography, Tooltip, darken, Stack, alpha} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {Variant} from '@mui/material/styles/createTypography'
import {SxProps} from '@mui/system'
import {scaleLinear} from 'd3'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

const roundAndConvertToPxString = (value: number) => `${Math.round(value)}px`

type SiloIndicatorProps = {
  id: number
  level: number
  capacity: number
  deadStockLevel: number
  minTargetLevel?: number
  height?: number
  width?: number | string
  siloWidth?: number
  siloName: string
  materialName?: string
  shouldShowName?: boolean
  nameLabelVariant?: Variant
  capacityLabelSX?: SxProps<Theme>
  stacked?: boolean
}

export const SiloIndicator: React.FC<SiloIndicatorProps> = ({
  id,
  level,
  capacity,
  deadStockLevel = 0,
  minTargetLevel = 0,
  height = 110,
  width = 64,
  siloWidth = 64,
  siloName,
  materialName,
  shouldShowName,
  nameLabelVariant = 'body2',
  capacityLabelSX,
  stacked
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const yScale = useMemo(
    () => scaleLinear().domain([0, capacity]).range([0, height]),
    [capacity, height]
  )
  const calculateRangeValueInPx = (domainValue: number) => {
    if (capacity === 0 && level > 0) {
      return height
    }
    if (capacity === 0 && level === 0) {
      return 0
    }
    return roundAndConvertToPxString(yScale(domainValue))
  }

  const isAboveTargetLevel = level >= minTargetLevel

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'left',
        overflow: 'hidden',
        width,
        ...(stacked ? {gap: 0.5} : {flexDirection: 'column'})
      }}
      {...dataTestId('silo_indicator')}
    >
      {/* Silo*/}
      <Tooltip
        title={
          <>
            <Typography variant="body1"> {t('stockCard.silo', {name: siloName})} </Typography>
            {Boolean(minTargetLevel) && (
              <Typography variant="body1">
                {t('stockCard.minStockLevel', {amount: minTargetLevel})}
              </Typography>
            )}
            <Typography variant="body1">
              {t('stockCard.deadStockLevel', {amount: deadStockLevel})}
            </Typography>
          </>
        }
      >
        <Box
          sx={({palette}) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            position: 'relative',
            border: `1px solid ${palette.grey[300]}`,
            backgroundColor: alpha(palette.background.default, 0.05),
            borderRadius: 0.5,
            overflow: 'hidden',
            height,
            minWidth: siloWidth
          })}
        >
          {/* Fill Level*/}
          <Box
            sx={{
              position: 'absolute',
              width: siloWidth,
              height: calculateRangeValueInPx(Math.min(level, capacity)),
              bottom: 0,
              left: 0,
              backgroundColor: isAboveTargetLevel ? 'primary.light' : 'error.main',
              transition: 'top 500ms, height 500ms'
            }}
          />
          {/* Minimum target level*/}
          {Boolean(minTargetLevel) && (
            <Box
              sx={(theme) => ({
                position: 'absolute',
                height: 0,
                left: 0,
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                bottom: calculateRangeValueInPx(Math.max(minTargetLevel, 0)),
                width: siloWidth
              })}
              {...dataTestId(`${id}_min_target_level`)}
            />
          )}
          {/* Dead stock*/}
          <Box
            sx={({palette}) => ({
              position: 'absolute',
              height: calculateRangeValueInPx(Math.min(deadStockLevel, level)),
              left: 0,
              bottom: 0,
              borderTop: `1px solid ${darken(
                isAboveTargetLevel ? palette.primary.light : palette.error.main,
                0.2
              )}`,
              backgroundColor: darken(
                isAboveTargetLevel ? palette.primary.light : palette.error.main,
                0.2
              ),
              width: siloWidth
            })}
            {...dataTestId(`${id}_dead_stock_level`)}
          />
        </Box>
      </Tooltip>
      <Stack justifyContent="space-between">
        <Stack>
          {/* Silo name*/}
          {shouldShowName && (
            <Typography
              variant={nameLabelVariant}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...(stacked
                  ? {
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2
                    }
                  : {whiteSpace: 'nowrap', mt: 0.25})
              }}
              {...dataTestId('silo_name')}
            >
              {siloName}
            </Typography>
          )}
          {/* Material name */}
          {materialName && (
            <Typography
              variant="caption"
              sx={{
                color: '#29333D',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...(stacked
                  ? {
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2
                    }
                  : {whiteSpace: 'nowrap'})
              }}
              {...dataTestId(`${siloName}_material_name`)}
            >
              {materialName}
            </Typography>
          )}
        </Stack>
        <Stack>
          {/* Fill level*/}
          {
            <Typography
              variant="subtitle1"
              sx={{whiteSpace: 'nowrap'}}
              {...dataTestId(`${siloName}_fill_level`)}
            >
              {formatTons(level, language)}
            </Typography>
          }
          {/* Capacity */}
          <Typography
            variant="caption"
            sx={mergeSx({zIndex: 1, fontWeight: 600, mb: stacked ? 0 : 0.5}, capacityLabelSX)}
          >
            {`/${formatTons(capacity, language)}`}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
