import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {EquipmentData, EquipmentPlain} from '../types'

import {useApi} from './useApi'

const ITEMS_PER_RESPONSE = 100

export const useEquipmentNumberSearchQuery = <TSelect = EquipmentPlain[]>(
  searchTerm: string | undefined,
  plantId: string,
  options?: UseQueryOptions<EquipmentPlain[], AxiosError, TSelect>
) => {
  const {publicApi} = useApi()
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<EquipmentPlain[], AxiosError, TSelect>(
    ['equipmentNumberSearch', searchTerm, language, plantId],
    async () => {
      if (!searchTerm || searchTerm.length < 2) {
        return Promise.resolve([])
      }

      const path = `/plantconfig/${plantId}/equipmentnumbers`
      const params = {
        params: {
          searchPattern: searchTerm,
          itemsPerResponse: ITEMS_PER_RESPONSE,
          language
        }
      }

      const response = await publicApi.get<EquipmentPlain[]>(path, params)
      return response.data
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}

export const useSpecificEquipmentNumberSearchQuery = (
  equipment: EquipmentData | null,
  plantId: string,
  options?: UseQueryOptions<EquipmentPlain[], AxiosError>
) => {
  const {publicApi} = useApi()
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<EquipmentPlain[], AxiosError>(
    ['specificEquipmentNumberSearch', equipment, plantId, language],
    async () => {
      if (!equipment) {
        return Promise.resolve([])
      }
      const path = `/plantconfig/${plantId}/equipment`
      const params = {params: {id: equipment.id, idType: equipment.idType, language}}

      const response = await publicApi.get<EquipmentPlain>(path, params)
      return [response.data]
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
