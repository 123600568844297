import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {EquipmentIdType, EquipmentPlain} from '../types'

import {useApi} from './useApi'

const QueryKey = 'equipment-data'

export const useEquipment = <Error = unknown>(
  plantId: string,
  equipmentId: string,
  idType: EquipmentIdType,
  language?: string,
  options?: UseQueryOptions<EquipmentPlain | undefined, AxiosError<Error>>
) => {
  const {publicApi} = useApi()
  const path = `/plantconfig/${plantId}/equipment`

  return useQuery<EquipmentPlain | undefined, AxiosError<Error>>(
    [QueryKey, equipmentId, idType, language, path],
    async () => {
      const {data} = await publicApi.get<EquipmentPlain>(path, {
        params: {
          idType,
          id: equipmentId,
          language
        }
      })
      return data
    },
    options
  )
}
