import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {AxiosError, AxiosInstance} from 'axios'

import {EquipmentData} from '../types'

const QueryKey = 'main-equipment-by-pxtrendtag-suggestion'

type MainEquipmentByPxTrendTagSuggestionArgs = {
  plantId: string
  pxTrendTag: string
  axiosInstance: AxiosInstance
}

export const getQueryKeyMainEquipmentByPxTrendTagSuggestion = (
  plantId: string,
  pxTrendTag: string
) => [`${QueryKey}-${plantId}-${pxTrendTag}`]

export const fetchMainEquipmentByPxTrendTagSuggestion = async ({
  plantId,
  pxTrendTag,
  axiosInstance
}: MainEquipmentByPxTrendTagSuggestionArgs) => {
  const response = await axiosInstance.get<EquipmentData>(
    `/shifthandover/${plantId}/main-equipment-by-pxtrendtag-suggestion/${pxTrendTag}`
  )

  return response.data
}

export const useMainEquipmentByPxTrendTagSuggestion = (
  {plantId, pxTrendTag, axiosInstance}: MainEquipmentByPxTrendTagSuggestionArgs,
  options?: UseQueryOptions<EquipmentData, AxiosError>
) => {
  return useQuery<EquipmentData, AxiosError>(
    getQueryKeyMainEquipmentByPxTrendTagSuggestion(plantId, pxTrendTag),
    async () => fetchMainEquipmentByPxTrendTagSuggestion({plantId, pxTrendTag, axiosInstance}),
    {...options}
  )
}
