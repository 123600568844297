import {datetimeFormatter, timeFormatter} from '@hconnect/uikit/src/common'
import {TFunction} from 'i18next'
import moment from 'moment-timezone'
import React from 'react'

import {RunningTime, RunningTimeType} from '../../types'

export const RUNNING_TIME_COLOR = {
  stoppedUndefined: 'warning.light',
  stoppedDefined: 'error.light',
  running: 'success.light',
  planned: 'grey.600'
}

// TODO: deprecate this function and use getRunningTimeDuration instead
export const getRunningTimeData = (
  {beginTechnical, endTechnical, runningTimeType}: RunningTime,
  language: string,
  t: TFunction | ((key: string) => string)
) => {
  const isDateSame = moment(beginTechnical).isSame(endTechnical, 'day')

  return beginTechnical ? (
    <>
      {datetimeFormatter(beginTechnical, language)}
      {endTechnical && moment(endTechnical).isValid()
        ? ` - ${
            isDateSame
              ? timeFormatter(endTechnical, language)
              : datetimeFormatter(endTechnical, language)
          }`
        : ` - ${
            runningTimeType === RunningTimeType.STOPPED
              ? t('stackedBarChart.label.stillStopped')
              : t('stackedBarChart.label.stillRunning')
          }`}
    </>
  ) : (
    '-'
  )
}

export const getRunningTimeDuration = ({
  beginUtc,
  endUtc,
  timezone,
  runningTimeType,
  language,
  translationFn
}: {
  beginUtc?: string
  endUtc?: string
  timezone: string
  runningTimeType?: RunningTimeType
  language: string
  translationFn: TFunction | ((key: string) => string)
}) => {
  const start = moment.utc(beginUtc).tz(timezone)
  const end = moment.utc(endUtc).tz(timezone)
  const isDateSame = start.isSame(end, 'day')

  return beginUtc ? (
    <>
      {datetimeFormatter(start, language, start.utcOffset())}
      {endUtc && moment(endUtc).isValid()
        ? ` - ${
            isDateSame
              ? timeFormatter(end, language, end.utcOffset())
              : datetimeFormatter(end, language, end.utcOffset())
          }`
        : runningTimeType
          ? ` - ${
              runningTimeType === RunningTimeType.STOPPED
                ? translationFn('stackedBarChart.label.stillStopped')
                : translationFn('stackedBarChart.label.stillRunning')
            }`
          : ` - ${translationFn('stackedBarChart.label.ongoing')}`}
    </>
  ) : (
    '-'
  )
}
