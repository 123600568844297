import {useNotification} from '@hconnect/uikit'
import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {ArrowBackIos, ArrowForwardIos, Cancel} from '@mui/icons-material'
import {Button, CircularProgress, Theme, IconButton} from '@mui/material'
import Box from '@mui/material/Box'
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react'
import ReactDOM from 'react-dom'
import {useTranslation} from 'react-i18next'

import {useFile} from '../../hooks/useFile'
import {Attachment} from '../../types'

import {AttachmentHandler} from './AttachmentHandler'

const AttachmentPortal: React.FC<{children: React.ReactNode}> = (props) => {
  const domNode = document.getElementById('attachmentViewer')
  if (!domNode) {
    throw new Error('Could not find the attachmentViewer portal domNode')
  }

  return ReactDOM.createPortal(props.children, domNode)
}

/**
 * Modulo implementation that can handle negative operands
 */
function modulo(x: number, n: number) {
  return ((x % n) + n) % n
}

/**
 * prevent scroll issues when image viewer overlay is shown by hiding scrollbar
 */
function hideAndRestoreMainContentScrollbarEffect() {
  const originalClassNames = document.body.className
  document.body.className = 'inactive-body'
  return () => {
    document.body.className = originalClassNames
  }
}

const ICON_SIZE = 'min(4vmax, 50px)'
const NAV_ICON_PADDING = '25px'

const iconTheme = (theme: Theme) => ({
  zIndex: 1550,
  color: theme.palette.primary.main,
  position: 'fixed',
  '& svg': {
    fontSize: ICON_SIZE
  }
})

const iconNavTheme = (theme: Theme) => ({
  top: `calc(50vh - ${ICON_SIZE})`,
  cursor: 'pointer',
  ...iconTheme(theme)
})

export const EventAttachmentsDisplay: React.FC<{
  attachments: Array<Attachment>
  activeAttachmentId: number
  onClose: () => void
}> = ({attachments, activeAttachmentId, onClose}) => {
  const [source, setSource] = useState<string | undefined>(undefined)
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment | undefined>(undefined)
  const {t} = useTranslation()

  const [selectedId, setSelectedId] = React.useState<number>(activeAttachmentId)

  useEffect(hideAndRestoreMainContentScrollbarEffect, [])

  useMemo(() => {
    setSelectedAttachment(attachments.find((a) => a.id === selectedId))
  }, [attachments, selectedId])

  const step = (n: number) => (ev: React.SyntheticEvent) => {
    ev.stopPropagation()
    if (selectedAttachment) {
      const currentIndex = attachments.indexOf(selectedAttachment)
      const newIndex = modulo(currentIndex + n, attachments.length)
      const newActiveId = attachments[newIndex].id
      setSelectedId(newActiveId)
    }
  }

  const prev = step(-1)
  const next = step(+1)

  const download = (selectedAttachment: Attachment) => {
    if (source) {
      const link = document.createElement('a')
      link.href = source
      link.setAttribute('download', selectedAttachment.fileName)
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
      link.click()
    }
  }

  return (
    <>
      {selectedAttachment && (
        <AttachmentPortal>
          <Box
            sx={{height: '100vh', maxHeight: '100vh'}}
            onClick={onClose}
            data-test-id="attachment-viewer"
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              sx={{padding: customThemeConstants().defaultGridSpacing}}
            >
              <Button
                color="primary"
                onClick={() => download(selectedAttachment)}
                variant={'contained'}
              >
                {t('downloadFile')}
              </Button>

              <IconButton
                onClick={onClose}
                data-test-id="attachment-viewer-close-icon"
                color={'primary'}
              >
                <Cancel />
              </IconButton>
            </Box>
            {attachments.length > 1 && (
              <>
                <Box
                  sx={(theme) => ({
                    left: '0px',
                    py: 15,
                    pl: NAV_ICON_PADDING,
                    ...iconNavTheme(theme)
                  })}
                  component={'span'}
                  onClick={prev}
                  data-test-id="attachment-viewer-prev-icon"
                >
                  <ArrowBackIos />
                </Box>
                <Box
                  component={'span'}
                  sx={(theme) => ({
                    right: '0px',
                    py: 15,
                    pl: 7.5,
                    pr: NAV_ICON_PADDING,
                    ...iconNavTheme(theme)
                  })}
                  onClick={next}
                  data-test-id="attachment-viewer-next-icon"
                >
                  <ArrowForwardIos />
                </Box>
              </>
            )}

            <AutoscaledAttachment
              attachment={selectedAttachment}
              source={source}
              setSource={setSource}
            />
          </Box>
        </AttachmentPortal>
      )}
    </>
  )
}

export const AutoscaledAttachment: React.FC<{
  attachment: Attachment
  source: string | undefined
  setSource: Dispatch<SetStateAction<string | undefined>>
}> = ({attachment, source, setSource}) => {
  const {notify} = useNotification()

  const stopEvent = (ev: React.SyntheticEvent) => {
    ev.stopPropagation()
  }
  const {data, isFetching, error} = useFile(
    attachment.url ?? attachment.previewUrl,
    attachment.mediaType,
    attachment.id
  )

  useEffect(() => {
    if (data) {
      const blob = new Blob([data])
      const url = URL.createObjectURL(blob)
      setSource(url)
    } else if (error) {
      notify('warning', error.message)
    }
  }, [data, error, notify, setSource])

  return (
    <Box
      sx={{
        width: '100%',
        flex: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
          width: '100%',
          objectFit: 'contain'
        }
      }}
      onClick={stopEvent}
      data-test-id="autoscaled-attachment"
    >
      {isFetching ? (
        <CircularProgress />
      ) : (
        <AttachmentHandler
          key={attachment.id}
          file={source}
          alt={attachment.fileName}
          fullWidth={true}
          iconOrPreview="preview"
          mediaType={attachment.mediaType}
        />
      )}
    </Box>
  )
}
