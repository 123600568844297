import {IsoWeekDay} from '@hconnect/uikit'
import {Moment} from 'moment-timezone'

import {AppName} from '../hproduce'

import {Iso8601, Uuid} from './atomic.types'
import {RunningTime, RunningTimeDto} from './runningTimes.types'
import {StoppageKind, shifthandoverStoppagesList} from './shifthandoverStoppages.types'
import {StoppageTypeWithCode, StoppageTypeWithReason} from './stoppage.types'

// Find a list of priorities in the usePriorities hook
export type Priority = number

export interface Attachment {
  id: number
  fileName: string
  mediaType: string
  url: string
  // preview is available for images and unavailable for pdfs or other docs
  previewMediaType?: string
  previewUrl?: string
}

export type PriorityItem = {
  priorityNumber: string
  priorityNumberText?: string | null
  language?: string | null
}

export type PrioritiesTranslationMap = Map<Priority, string>

// RFC 7807 based Error payload
export type HttpProblem = {
  type: string
  title?: string
  detail?: string
  instance?: string
}

export type HttpError = HttpProblem & {code: number}

export type ShifthandoverStoppageCode = {
  code: string
  description: string
  type: StoppageKind
  stoppageCategory?: Category
}

export type DowntimeStoppageCode = {
  code: string
  description: string
  type: StoppageKind
  priority?: number
}

export const eventTypesInterchangeable = [
  'malfunction',
  'healthAndSafety',
  'task',
  'information',
  'idea',
  'environment',
  'parameterChange',
  'quality'
] as const
export type EventTypeInterchangeable = (typeof eventTypesInterchangeable)[number]
export const eventTypes = [...shifthandoverStoppagesList, ...eventTypesInterchangeable] as const
export type EventType = (typeof eventTypes)[number]

export const equipmentIdTypes = [
  'technicalId',
  'sapNumber',
  'processId',
  'mainEquipmentCustomId',
  'janusId'
] as const
export type EquipmentIdType = (typeof equipmentIdTypes)[number]

export enum EquipmentCategory {
  CEMENT = 'cement',
  CLINKER = 'clinker',
  CRUSHER = 'crusher',
  RAW = 'raw',
  COAL = 'coal',
  UNKNOWN = 'unknown'
}

export const categories = [
  'mechanical',
  'electrical',
  'quality',
  'process',
  'environment',
  'healthAndSafety',
  'production',
  'cleaning',
  'cost',
  'other'
] as const
export type Category = (typeof categories)[number]

export const maintenanceNotifications = ['withNotifications', 'withoutNotifications'] as const
export type MaintenanceNotification = (typeof maintenanceNotifications)[number]

// Note: cementMill, rawMill, coalMill and crusher are currently not equipment types
// they are only possible equipment categories which map to the stoppage types
export enum EquipmentType {
  KILN = 'kiln',
  VERTICAL_MILL = 'verticalMill',
  ROLLER_PRESS = 'rollerPress',
  DOSING_EQUIPMENT = 'dosingEquipment',
  BALL_MILL = 'ballMill',
  OTHER = 'other'
}

export type EquipmentData = {
  idType?: EquipmentIdType
  id: string
  text?: string
  equipmentCategory?: EquipmentCategory
  equipmentType?: EquipmentType
}

export type RunningTimesEquipmentData = {
  idType?: EquipmentIdType
  id: string
  text?: string
  matchingId: string
  equipmentCategory?: EquipmentCategory
  equipmentType?: EquipmentType
}

export type EquipmentRunningTimesDto = {
  equipment: RunningTimesEquipmentData
  runningTimes: RunningTimeDto[]
  totalRunning: string
  totalStopped: string
}
export type EquipmentRunningTimes = {
  equipment: RunningTimesEquipmentData
  runningTimes: RunningTime[]
  totalRunning: string
  totalStopped: string
}
export type PlantRunningTimesDto = {
  plantId: string
  equipmentsWithRunningTimes: EquipmentRunningTimesDto[]
}

export type StoppageDetails =
  | {
      stoppageType: StoppageTypeWithCode
      stoppageCode: string
    }
  | {
      stoppageType: StoppageTypeWithReason
      stoppageReason: string
    }

export type ProcessStage = {
  plantId: string
  processStep: string
  processStepValue: string
}
export type EquipmentPlain = {
  equipment: EquipmentData
  processStage?: ProcessStage
  path?: EquipmentData[]
  mainEquipment?: EquipmentData
  defaultWorkCenterId?: string
  defaultPlannerGroup?: string
}

export const taskRepetitionUnits = ['daily', 'weekly', 'monthly', 'yearly'] as const
export type TaskRepetitionUnit = (typeof taskRepetitionUnits)[number]

export type WorkCenter = {id: string; text: string}

export enum WorkOrderTarget {
  Sap = 'sap',
  Infor = 'infor'
}

export type InforWorkOrder = {
  requestId: string
}

export type User = {
  id: Uuid
  name: string
}

export interface AttachmentFile {
  id?: number
  preview: string
  name: string
  contentType: string
  isImage?: boolean
  originalFile?: File
}

export interface Info {
  // username
  createdBy: User
  modifiedBy: string
  created: Iso8601
  lastModified: Iso8601
  lastModifiedStatus?: Iso8601
}

export interface Id {
  id: string
}

export type Assignees =
  | {
      assignees: User[]
    }
  | {
      assignees: never
    }

export const statusListWithCancellationOption = ['pending', 'ongoing', 'done', 'cancelled'] as const
export type StatusWithCancellation = (typeof statusListWithCancellationOption)[number]

export type QuickSelectRepetition = {
  repetition: TaskRepetitionUnit
  enabled?: boolean
}

type EndingOptionsDto =
  | {endDate: Iso8601; endsAfterRepetitions?: never} // endDate or
  | {endDate?: never; endsAfterRepetitions: number} // endsAfterRepetitions or
  | {endDate?: never; endsAfterRepetitions?: never} // non
type EndingOptions =
  | {endDate: Moment; endsAfterRepetitions?: never} // endDate or
  | {endDate?: never; endsAfterRepetitions: number} // endsAfterRepetitions or
  | {endDate?: never; endsAfterRepetitions?: never} // non

type CustomDailyRepetitionDto = EndingOptionsDto & {
  repetition: 'daily'
  startDate: Iso8601
  repeatEvery: number
  enabled?: boolean
}
export type CustomDailyRepetition = EndingOptions & {
  repetition: 'daily'
  startDate: Moment
  repeatEvery: number
  enabled?: boolean
}

type CustomWeeklyRepetitionDto = EndingOptionsDto & {
  repetition: 'weekly'
  startDate: Iso8601
  repeatEvery: number
  weekDays: IsoWeekDay[]
  enabled?: boolean
}
export type CustomWeeklyRepetition = EndingOptions & {
  repetition: 'weekly'
  startDate: Moment
  repeatEvery: number
  weekDays: IsoWeekDay[]
  enabled?: boolean
}

export type CustomTaskRepetition = CustomDailyRepetition | CustomWeeklyRepetition
export type TaskRepetitionDto =
  | QuickSelectRepetition
  | CustomDailyRepetitionDto
  | CustomWeeklyRepetitionDto
export type TaskRepetition = QuickSelectRepetition | CustomDailyRepetition | CustomWeeklyRepetition

export type TaskPreviewData = TaskRepetitionDto & {
  itemsCount: number
  startDate?: Iso8601
}

// Note: Not present in shifthandover events
// TODO: check with BE on implementation
export type EventAppInfo = {app: AppName; plantId: string; externalId}

export type SharedEventStructureDto = {
  category: Category
  description: string
  title: string
  commentCount: number

  // optional
  isHighlighted?: boolean
  equipment?: EquipmentData
  mainEquipment?: EquipmentData
  processStage?: string
  priority?: Priority
  schedule?: {
    startDate: Iso8601
    endDate: Iso8601
  }
  inforWorkOrder?: InforWorkOrder
}

export type ShiftEventTaskDto = Omit<SharedEventStructureDto, 'schedule'> &
  Assignees & {
    dueDate: Iso8601
    eventType: 'task'
    status: StatusWithCancellation

    // optional
    toggleAssigneeNotification?: boolean
    repetitionInfo?: TaskRepetitionDto | null
    doNotStartBefore?: Iso8601
    isChecklistsCreationFailed?: boolean
  }

export type StatusWithNoneAndCancellationAndApproverAndWorkOrderStates =
  | 'none'
  | 'pending'
  | 'ongoing'
  | 'done'
  | 'cancelled'
  | 'approved'
  | 'rejected'
  | 'waitingForApproval'
  | 'workOrderCreated'

export enum EventsTab {
  Events = 'Events',
  WorkOrders = 'WorkOrders',
  Notifications = 'Notifications'
}

export type ShiftEventCommentDto = {
  id: string
  description: string
  createDate: Iso8601
  isEditable?: boolean
  creator: string
  creatorId: Uuid
}

export type ShiftEventComment = {
  id: string
  description: string
  createDate: Moment
  isEditable?: boolean
  creator: string
  creatorId: Uuid
}
