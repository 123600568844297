import {useNotification} from '@hconnect/uikit'
import {CircularProgress, Box} from '@mui/material'
import React, {useMemo, useState} from 'react'

import {useFile} from '../../hooks/useFile'
import {Attachment} from '../../types'

import {AttachmentHandler, imageFluidSx} from './AttachmentHandler'

export const EventAttachment: React.FC<{
  attachment: Attachment
  setActiveAttachmentId: (id: number) => void
}> = ({attachment, setActiveAttachmentId}) => {
  const [source, setSource] = useState<string | null>(null)
  const {notify} = useNotification()
  const fileUrl = attachment.previewUrl || attachment.url
  const {data, isFetching, error} = useFile(fileUrl, attachment.mediaType, attachment.id)

  useMemo(() => {
    if (data) {
      const blob = new Blob([data])
      const url = URL.createObjectURL(blob)
      setSource(url)
    } else if (error) {
      notify('warning', error.message)
    }
  }, [data, error, notify])

  return (
    <span key={attachment.url} onClick={() => setActiveAttachmentId(attachment.id)}>
      {isFetching ? (
        <Box ml={2}>
          <CircularProgress />
        </Box>
      ) : (
        <AttachmentHandler
          key={attachment.id}
          file={source}
          alt={attachment.fileName}
          iconOrPreview={attachment.mediaType.includes('image') ? 'preview' : 'icon'}
          mediaType={attachment.mediaType}
          clickable={true}
          sx={imageFluidSx}
        />
      )}
    </span>
  )
}
