import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {PrioritiesTranslationMap, PriorityItem as PriorityConfig} from '../types'

import {useApi} from './useApi'

const QueryKey = 'config-priorities'

// Most plants have the list of priorities set up like [1, 2, 3, 4, 5, 6, 9]
// The array maps to certain priorities like 1 - Immiedately and so on

export const usePriorities = (options?: UseQueryOptions<PrioritiesTranslationMap, AxiosError>) => {
  const {publicApi} = useApi()
  const {
    i18n: {language}
  } = useTranslation()
  const path = `/plantconfig/${language}/priorities`

  return useQuery<PrioritiesTranslationMap, AxiosError>(
    [QueryKey, language],
    async () => {
      const list = await publicApi.get<PriorityConfig[]>(path)
      return new Map(
        list.data.map((priority: PriorityConfig) => {
          return [
            parseInt(priority.priorityNumber, 10),
            priority.priorityNumberText ?? priority.priorityNumber
          ]
        })
      )
    },
    options
  )
}
