import {Box, darken, Stack, Tooltip, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import {isEmpty} from 'lodash'
import React, {ReactNode, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {RunningTime, RunningTimeType, StoppageKind} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

import {getRunningTimeDuration, RUNNING_TIME_COLOR} from './common'

export interface BarForChartProps {
  percentage: string
  timezone: string
  runningTime?: RunningTime
  stoppageIndex?: number
  sx?: SystemStyleObject<Theme>
  handleStoppageClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  handleRunningClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  aboveBarComponent?: ReactNode
  tooltip?: {
    header?: ReactNode
    body?: ReactNode
    footer?: ReactNode
  }
  translationPrefix?: string
}

const smallBarSx = {
  height: 12
}

export const BarForChart: React.FC<BarForChartProps> = ({
  percentage,
  timezone,
  runningTime,
  stoppageIndex,
  handleStoppageClick,
  handleRunningClick,
  sx,
  aboveBarComponent,
  tooltip,
  translationPrefix
}) => {
  const {i18n, t} = useTranslation()
  const language = i18n.language

  const getTimeTooltipContent = useCallback(
    (runningTime: RunningTime, stoppageIndex = 0) => {
      const begin = !isEmpty(runningTime.stoppages)
        ? runningTime.stoppages?.[stoppageIndex].start
        : runningTime.beginTechnical
      const end = !isEmpty(runningTime.stoppages)
        ? runningTime.stoppages?.[stoppageIndex].end
        : runningTime.endTechnical

      return (
        <Stack spacing={2} data-test-id={`tooltip-${runningTime.runningTimeType}`}>
          {tooltip?.header}
          {tooltip?.body || (
            <Typography>
              {getRunningTimeDuration({
                beginUtc: begin?.toISOString(),
                endUtc: end?.toISOString(),
                timezone: timezone,
                runningTimeType: runningTime.runningTimeType,
                language,
                translationFn: (key: string) => t(getTranslationKey(key, translationPrefix))
              })}
            </Typography>
          )}
          {tooltip?.footer}
        </Stack>
      )
    },
    [tooltip, language, t, translationPrefix, timezone]
  )

  if (!runningTime?.runningTimeType) {
    return (
      <Box sx={{flexBasis: percentage}}>
        {aboveBarComponent}
        <Box
          sx={[
            {
              flexBasis: percentage
            },
            (theme: Theme) => getStyle(theme, undefined),
            smallBarSx,
            !!sx && sx
          ]}
        />
      </Box>
    )
  }

  return (
    <>
      {runningTime && (
        <Tooltip arrow title={getTimeTooltipContent(runningTime, stoppageIndex)}>
          <Box
            onClick={
              runningTime.runningTimeType === RunningTimeType.STOPPED
                ? handleStoppageClick
                : handleRunningClick
            }
            sx={[
              {
                flexBasis: percentage,
                zIndex: 1
              },
              (theme: Theme) =>
                getStyle(
                  theme,
                  runningTime,
                  !!handleStoppageClick,
                  !!handleRunningClick,
                  stoppageIndex
                ),
              smallBarSx,
              !!sx && sx
            ]}
            data-test-id={`bar-for-chart-${runningTime.runningTimeType}`}
          />
        </Tooltip>
      )}
    </>
  )
}

function getStyle(
  theme: Theme,
  runningTime: RunningTime | undefined,
  stoppedClickable?: boolean,
  runningClickable?: boolean,
  stoppageIndex: number = 0
) {
  switch (runningTime?.runningTimeType) {
    case RunningTimeType.RUNNING:
      return {
        backgroundColor: RUNNING_TIME_COLOR.running,
        cursor: runningClickable ? 'pointer' : 'default'
      }
    case RunningTimeType.STOPPED:
      if (!runningTime.stoppages?.[stoppageIndex]?.cockpitStoppageId) {
        return {
          backgroundColor: RUNNING_TIME_COLOR.stoppedUndefined,
          cursor: stoppedClickable ? 'pointer' : 'default'
        }
      }
      return runningTime.stoppages?.[stoppageIndex]?.stoppageKind === StoppageKind.PlannedStoppage
        ? {
            backgroundColor: RUNNING_TIME_COLOR.planned,
            cursor: 'pointer'
          }
        : {
            backgroundColor: RUNNING_TIME_COLOR.stoppedDefined,
            cursor: stoppedClickable ? 'pointer' : 'default',
            ...(stoppageIndex && {
              borderLeft: `1px solid ${darken(theme.palette.error.main, 0.15)}`
            }),
            '&:hover': {
              backgroundColor: theme.palette.error.main
            },
            '&:active': {
              backgroundColor: theme.palette.error.dark
            }
          }

    case undefined:
    case null:
    default:
      return {backgroundColor: 'grey.200'}
  }
}
